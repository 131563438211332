import React, { useEffect, useState } from 'react'

interface TagInputProps {
  defaultValue: string[]
  placeholder?: string
  onChange: (tags: string[]) => void
}

function TagInput({ defaultValue, placeholder, onChange }: TagInputProps) {
  const [tags, setTags] = useState<string[]>(defaultValue ?? [])
  const [inputValue, setInputValue] = useState('')

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && inputValue.trim()) {
      if (!tags.includes(inputValue.trim())) {
        setTags([...tags, inputValue.trim()])
      }
      setInputValue('')
      event.preventDefault() // Prevent form submission on Enter
    }
  }

  function handleRemoveTag(tagToRemove: string) {
    setTags(tags.filter((tag) => tag !== tagToRemove))
  }

  useEffect(() => {
    onChange(tags)
  }, [tags])

  return (
    <div className="form-control">
      <div className="input-group">
        <input
          type="text"
          className="form-control border-white fs-6"
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown} />
      </div>
      <div className="d-flex align-items-center gap-2 mt-2">
        {tags.map((tag, index) => (
          <span key={index} className="py-1 px-2 border rounded-2 fs-6">
            {tag}
            <button
              type="button"
              className="bg-transparent border-0 ms-1 p-0 text-sm"
              onClick={() => handleRemoveTag(tag)}
              style={{ width: '20px' }}
            >
              <i className="fa-solid fa-xmark" aria-hidden="true"></i>
            </button>
          </span>
        ))}
      </div>
    </div>
  )
}

export default TagInput;
