import React, { useRef } from "react"
import { T } from "../../utils/i18n-config";
import DropzoneComponent from "../utils/Dropzone"
import DropzoneDocumentSamplesComponent from "../../components/utils/DropzoneDocumentSamples";


interface Button {
  text: string;
  icon: string;
  onClick: (iElement: number) => void;
}


export interface DropFile {
  file: File;
  preview: string;
}

interface DropzoneComponentProps {
  elementId: number;
  // attachmentName?: string[];
  attachmentName?: { id: number, name: string }[];
  onUpload: (attachmentName: string[], originalFilename: DropFile[]) => void;
  onRemove: (attachmentName: { id: number, name: string }) => void;
}


const DropzoneComponentSamplesView: React.FC<DropzoneComponentProps> = ({ elementId, attachmentName, onUpload, onRemove }) => {

  return (
    <div>
      <DropzoneDocumentSamplesComponent
        elementId={elementId}
        attachmentName={attachmentName}
        onUpload={onUpload}
        onRemove={onRemove}
      />
    </div>
  );
}

export default DropzoneComponentSamplesView;
