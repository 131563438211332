import React, { useState, useRef, useEffect } from 'react';
import { T } from "../../utils/i18n-config"
import EditModeBase from "../special-document/EditModeBase";
import EditModeProps from '../special-document/EditModeProps';
import { Permissions } from '../../models/special-document/ElementBase';
import ModalBase from '../modals/ModalBase';
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ConditionalParagraphOption } from '../../models/special-document/ElementArgs';
import ModalWithButtons from '../modals/AcceptModalBase';
import { createId } from '../../utils/utilities';
import { ConditionalParagraphElementWithSelector } from "../../models/special-document/ConditionalParagraphElementWithSelector"


const QuestionBankConciliationsEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<ConditionalParagraphElementWithSelector>(iElement as ConditionalParagraphElementWithSelector).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [question, setQuestion] = useState('')
  const [options, setOptions] = useState<ConditionalParagraphOption[]>([])
  const [isEditable , setIsEditable] = useState<boolean>(false);

  useEffect(() => {
    setQuestion(element.question)
    setOptions(JSON.parse(JSON.stringify(element.options)))

    if(element.options.length > 0){
      const currentOptions = element.options;
      if(currentOptions.some(option => option.editable)){
        setIsEditable(true);
      }
    }
    else{
      setIsEditable(false);
    }
  }, []); 

  const handleConfiguration = () => {
    setQuestion(element.question)
    setOptions(JSON.parse(JSON.stringify(element.options)));
    setShowConfigurationsModal(true);
  };

  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);    
    const elementRepository = new ElementRepository()
    const lastQuestion = element.question
    const lastOptions = JSON.parse(JSON.stringify(element.options))
    element.question = question
    element.options = JSON.parse(JSON.stringify(options))

    let success = await elementRepository.saveElement("edition", element.args)
    //let success = false
    if (!success) {
      element.question = lastQuestion
      element.options = lastOptions
      setQuestion(lastQuestion)
      setOptions(JSON.parse(JSON.stringify(lastOptions)))
      window.htmlHelpers?.swalError()
    }
  };

  const handleConfigurationsModalCancel = () => {
    setShowConfigurationsModal(false)
    setOptions([])
    setQuestion('')
    setIsEditable(element.options.some(option => option.editable));
  };

  const handleAddValueMessage = () => {
    const conditionalParagraphOption: ConditionalParagraphOption = {
      id: createId(),
      value: "",
      message: "",
      editable: false
    }
    const newOptions = [...options, conditionalParagraphOption]
    setOptions(newOptions)
  }

  const handleRemoveLastValueMessage = () => {
    const newOptions = [...options]
    newOptions.pop()
    setOptions(newOptions)
  }

  const handleEditValue = (index: number, value: string) => {
    const newOptions = [...options]
    newOptions[index].value = value
    setOptions(newOptions)
  }

  const handleEditMessage = (index: number, message: string) => {
    const newOptions = [...options]
    newOptions[index].message = message
    setOptions(newOptions)
  }

  const handleSetIsEditable = (index: number, editable: boolean) => {
    const newOptions = [...options];
    newOptions[index].editable = editable;
    setOptions(newOptions);
    if(editable){
      setIsEditable(true);
    }
    else if(!newOptions.some(option => option.editable)){
        setIsEditable(false);
    }
  }

  const handleSetHasAttachment = (index: number, hasAttachment: boolean) => {
    const newOptions = [...options]
    newOptions[index].has_attachment = hasAttachment
    setOptions(newOptions)
  }

  const handleEditAttachmentName = (index: number, attachment_name: string) => {
    const newOptions = [...options]
    newOptions[index].attachment_name = attachment_name
    newOptions[index].has_attachment = true
    setOptions(newOptions)
  }


  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={isEditable}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div>
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("The client uses other asset or liability accounts to transfer amounts from reconciling items?")}</h6>
              <div className="chart pt-3">
                <ul>
                      <li key={1}>
                        <div className="row">
                          <div className="col-2">
                            <span className="text-sm font-weight-normal">{T("Yes")}</span>
                          </div>
                        </div>
                      </li>
                      <li key={2}>
                        <div className="row">
                          <div className="col-2">
                            <span className="text-sm font-weight-normal">{T("No")}</span>
                          </div>
                        </div>
                      </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Question")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}>
        <div className="card-body p-3">
              <h6>{T("The client uses other asset or liability accounts to transfer amounts from reconciling items?")}</h6>
              <div className="chart pt-3">
                <ul>
                      <li key={1}>
                        <div className="row">
                          <div className="col-2">
                            <span className="text-sm font-weight-normal">{T("Yes")}</span>
                          </div>
                        </div>
                      </li>
                      <li key={2}>
                        <div className="row">
                          <div className="col-2">
                            <span className="text-sm font-weight-normal">{T("No")}</span>
                          </div>
                        </div>
                      </li>
                 
                </ul>
              </div>
            </div>
      </ModalWithButtons>
    </div>
  )
}
export default QuestionBankConciliationsEditMode;
