import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import ViewModeBase from "../special-document/ViewModeBase";
import { formatCurrency } from '../../utils/utilities';
import { RegistersSamplingDifference, SamplingDifferenceBankConciliationsRepository } from '../../repository/special-document/SamplingDifferenceBankConciliationsRepository'
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';

export interface AccountData {
  id: number;
  consignment_number: number;
  type_account: string;
  account: string;
  name: string;
  amount: number;
  amount_in_other_accounts: number;
  amount_per_statement: number;
  difference_amount: number;
}


const SamplesBankConciliationsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [isRefresh, setIsRefresh] = useState(false);
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [samplesBankConciliations, setSamplesBankConciliations] = useState<RegistersSamplingDifference[]>([]);

  const getSamplesSubsTestBankConciliations = async () => {
    const samplingDifferenceBankConciliationsRepository = new SamplingDifferenceBankConciliationsRepository()
    const result = await samplingDifferenceBankConciliationsRepository.getRegistersSamplingDifference(element.args.engagement_id, element.args.document_id, element.reference)
    if (Array.isArray(result)) {     
      setSamplesBankConciliations(result)
    }
  }

  useEffect(() => {
    const handleEvent = () => {
      setIsRefresh(prev => !prev);
    };
    emitter.on("refreshSubstantiveBalanceData", handleEvent);
    return () => {
      emitter.off("refreshSubstantiveBalanceData", handleEvent);
    };
  }, []);

  useEffect(() => {
    getSamplesSubsTestBankConciliations()
  }, [isRefresh, isRefreshLocal]);

 

  return (
    <div>
      {samplesBankConciliations && samplesBankConciliations.length > 0 && (
      <ViewModeBase
        isEditable={false}
        permissions={element.permissions as Permissions}
      >
        <div className="d-flex w-full flex-column mb-3">
            <div className="d-flex justify-content-between mb-">
              <h6>{T("Present differences")}</h6>
            </div>
            <table className="table-bordered">
              <thead>
                <tr>
                  <th className="p-2" style={{ width: "50%"}}>{T("Account")}</th>
                  <th className="p-2" style={{ width: "50%"}}>{T("Difference")}</th>
                </tr>
              </thead>
              <tbody>
                {samplesBankConciliations.map((item) => (
                  <tr key={item.id}>
                    <td className="p-1">{item.account}</td>
                    <td className="p-1">{formatCurrency(item.difference_value)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
      </ViewModeBase>
      )}
    </div>
  );
};

export default SamplesBankConciliationsViewMode;
