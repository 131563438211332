import { RequiredAccountConfigurationBankConciliations } from '@models/special-document/ElementArgs';
import React, { useEffect, useState, useRef } from 'react';


interface Select2Props {
  options: RequiredAccountConfigurationBankConciliations[];
  multiple?: boolean;
  className?: string;
  placeholder?: string;
  onChange: (value: any) => void;
}

const Select2AccountConfiguration = ({ options, multiple, className, placeholder, onChange }: Select2Props) => {
  const [search, setSearch] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<RequiredAccountConfigurationBankConciliations[]>([]);
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const selectInputContainerRef = useRef<HTMLDivElement>(null);
  const selectInputRef = useRef<HTMLInputElement>(null);
  const filteredOptions = options.filter((option) => 
    option?.code?.toString().toLowerCase().includes(search.toLowerCase())
  );
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        selectContainerRef.current &&
        selectInputContainerRef.current &&
        !selectContainerRef.current.contains(event.target as Node)
      ) {
        selectInputContainerRef.current.classList.remove('active');
      }
    }

    document.addEventListener('click', handleClickOutside);
    selectInputContainerRef.current?.addEventListener('click', focusInputContainer)
    selectInputRef.current?.addEventListener('click', focusInputContainer)

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, []);

  useEffect(() => {
    if (selectedUsers.length > 0) {
      onChange(selectedUsers[0].id);
      setSelectedUsers([]);
    }

    if (selectedUsers.length === 0) selectInputRef.current?.classList.remove('py-2');
    else selectInputRef.current?.classList.add('py-2');
  }, [selectedUsers]);

  function focusInputContainer() {
    selectInputContainerRef.current?.classList.add('active');
    selectInputRef.current?.focus();
  }

  function handleSelectUser(user: RequiredAccountConfigurationBankConciliations) {
    const isUserSelected = selectedUsers.find((selectedUser) => selectedUser.id === user.id);
    if (!multiple) setSelectedUsers([user]);
    else if (!isUserSelected) setSelectedUsers([...selectedUsers, user]);
    else setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== user.id));
  }

  function handleRemoveUser(user: RequiredAccountConfigurationBankConciliations) {
    setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== user.id));
  }

  function handleClearUsers() {
    setSelectedUsers([]);
  }

  function checkActiveUser(user: RequiredAccountConfigurationBankConciliations) {
    return selectedUsers.find((selectedUser) => selectedUser.id === user.id)
  }

  return (
    <div ref={selectContainerRef} className={`${className} custom-select2-container d-flex flex-column gap-2`}>
      <div
        ref={selectInputContainerRef}
        className={"custom-select2-input active position-relative py-2 px-2 pe-4 border rounded-3" + `${selectedUsers.length > 0 ? ' filled' : ''}`}
      >
        <div className="custom-select2-selected d-flex flex-wrap gap-2 pe-2">
          {
            selectedUsers.map((user) => (
              <div key={user.id} className="py-1 px-2 border rounded-2">
                <button className="bg-transparent border-0 p-0 text-sm" onClick={() => handleRemoveUser(user)}>
                  <i className="fa-solid fa-xmark"></i>
                </button>
                <span className="ms-1">{user.code}</span>
              </div>
            ))
          }
        </div>
        {
          selectedUsers.length > 0 && (
            <button className="custom-select2-clear p-0 bg-transparent border-0 text-muted fs-5" onClick={handleClearUsers}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          )
        }
        <input
          type="text"
          value={search}
          ref={selectInputRef}
          className="custom-select2-search py-2 border-0"
          placeholder={placeholder}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="custom-select2-options d-none border rounded-3" style={{ maxHeight: '200px', overflowY: 'auto' }}>
        <ul className="list-group d-flex flex-column">
          {
            filteredOptions.map((option) => (
              <li
                key={option.id}
                className={"list-group-item d-flex justify-content-between align-items-center border-0" + `${checkActiveUser(option) ? ' active' : ''}`}
                onClick={() => handleSelectUser(option)}
              >
                <span className="fullname">{option.code}</span>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )
};

export default Select2AccountConfiguration;