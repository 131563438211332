import React, { useEffect, useState } from 'react';
import AddElementModal from './AddElementModal'
import { T } from "../../utils/i18n-config"
import { DocumentSpecialFactory } from "../../factory/special-document/DocumentSpecialFactory"
import { DocumentSpecial } from "../../models/special-document/DocumentSpecial"
import { ElementFactory } from "../../factory/special-document/ElementFactory"
import { IElementBase, Permissions } from '../../models/special-document/ElementBase';
import { ElementRepository} from "../../repository/special-document/ElementRepository"
import { CreateEditModeComponentProps, CreateViewModeComponentProps, elementComponentsEditionMode, elementComponentsViewMode } from "../../templates/ElementTypeTemplate"
import { Constants } from '../../utils/Constants';

interface BoardProps {
  name?: string
  reference: string
  engagementReference: string | undefined
  renderingMode: "edition" | "view"
  engagementID: number | null
  permissions: Permissions
}


const Board = ({ name, reference, engagementReference, renderingMode, engagementID, permissions }: BoardProps) => {
  const [documentSpecial, setDocumentSpecial] = useState<DocumentSpecial>();
  const [content, setContent] = useState<IElementBase[]>();
  const [addElementshowModal, setAddElementshowModal] = React.useState(false);
  
  useEffect(() => {
    setContent(undefined);
    const documentSpecialFactory = new DocumentSpecialFactory();
    const fetchDocumentSpecial = async () => {
      try {
        const result = await documentSpecialFactory.createDocumentSpecial(reference, renderingMode, engagementID, name);
        result.content.map((element) => {element.permissions = permissions; element.reference = engagementReference;})
        setContent(result.content);
        setDocumentSpecial(result);
      } catch (error) {
        window.htmlHelpers?.swalError()
      }
    };

    fetchDocumentSpecial();
  }, [reference, renderingMode, engagementID]);


  const handleAddElement = async (elemetType: number) => {
    setAddElementshowModal(false);

    if (documentSpecial) {
      const elementFactory = new ElementFactory();
      let iElement = await elementFactory.createClassByType(elemetType, documentSpecial.id, engagementID);
      
      if (iElement) {
        iElement.permissions = permissions;

        if (iElement.type === Constants.VARIANCE_ANALYSIS_NOTES_TYPE) {
          const resultContent = await elementFactory.loadClassessByDocumentId(documentSpecial.id, renderingMode, engagementID) 
          iElement = resultContent.find((element) => element.id === iElement.id) as IElementBase
          iElement.permissions = permissions;
        }
        const updatedContent: IElementBase[] = [
          ...content!,
          iElement
        ];
        setContent(updatedContent)
      }
    }
  };


  const handleDeleteElement = async (iElement: IElementBase) => {
    if (documentSpecial) {
      const elementRepository = new ElementRepository();
      let success = await elementRepository.deleteElement(iElement.engagement_id, iElement.documentId, iElement.id);
      if (success) {
        const updatedContent: IElementBase[] = content!.filter((item) => item.id !== iElement.id);
        setContent(updatedContent)
      } else {
        window.htmlHelpers?.swalError()
      }
    }
  }

  const swapElements = async (success: boolean, id: number, swapId: number | null) => {
    if (success && content !== undefined) {
      if (swapId === null) return
      const contentCopy = content.slice()
      const index = contentCopy.findIndex((element) => element.id == id)
      const swapIndex = contentCopy.findIndex((element) => element.id == swapId)
      const temp = contentCopy[index]
      contentCopy[index] = contentCopy[swapIndex]
      contentCopy[swapIndex] = temp
      setContent(contentCopy)
    } else {
      window.htmlHelpers?.swalError()
    }
  }

  const handleUpElement = async (iElement: IElementBase) => {
    if (documentSpecial) {
      const elementRepository = new ElementRepository();
      let data = await elementRepository.upElement(iElement.engagement_id, iElement.documentId, iElement.id);
      swapElements(data.success, iElement.id, data.swapId)
    }
  }

  const handleDownElement = async (iElement: IElementBase) => {
    if (documentSpecial) {
      const elementRepository = new ElementRepository();
      let data = await elementRepository.downElement(iElement.engagement_id, iElement.documentId, iElement.id);
      swapElements(data.success, iElement.id, data.swapId)
    }
  }


  const clickOutside = (event: any) => {
    if(renderingMode === "view") return;
    if(event.target.classList.contains('drop-activation-button-react')) return;
    const elements = document.getElementsByClassName('drop-menu-react');
    Array.from(elements).forEach((element) => {
      const htmlElement = element as HTMLElement;
      if (htmlElement.classList.contains('show')) {
        htmlElement.classList.remove('show');
      }
    });
  }

  useEffect(() => {
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('click', clickOutside);
    }
  },[])

  const handleAddElementCancel = () => {
    setAddElementshowModal(false);
  };

  const CreateViewModeComponent: React.FC<CreateViewModeComponentProps> = ({ elementType, iElement }) => {
    const ElementViewMode = elementComponentsViewMode[elementType];

    if (!ElementViewMode) {
      return <div>{T("Unknown Element")}</div>;
    }

    return <ElementViewMode iElement={iElement} />;
  };

  const CreateEditModeComponent: React.FC<CreateEditModeComponentProps> = ({ elementType, iElement, handleDeleteElement, handleUpElement, handleDownElement }) => {
    const ElementEditMode = elementComponentsEditionMode[elementType];

    if (!ElementEditMode) {
      return <div>{T("Unknown Element")}</div>;
    }
    
    return <ElementEditMode iElement={iElement} handleDeleteElement={handleDeleteElement} handleUpElement={handleUpElement} handleDownElement={handleDownElement} />;
  };

  return (
    <div>
      {renderingMode === "edition" && permissions.create && documentSpecial?.state === "open" &&
        <button className="btn bg-gradient-primary" onClick={() => setAddElementshowModal(true)}>{T("Add Element")}</button>
      }
      <AddElementModal
        showModal={addElementshowModal}
        handleAddElement={handleAddElement}
        handleAddElementCancel={handleAddElementCancel}
        reference={reference}/>

      {content === undefined &&
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50%" }}>
          <div className="spinner-border">
            <output className="sr-only">Loading...</output>
          </div>
        </div>
      }
      {content?.map((iElement) =>
        <div key={'element'+iElement.id} >
          {
            renderingMode === "view" ?
              CreateViewModeComponent({ elementType: iElement.type, iElement }) :
              CreateEditModeComponent({ elementType: iElement.type, iElement, handleDeleteElement, handleUpElement, handleDownElement })
          }
        </div>
      )}
    </div>

  );
};

export default Board;
