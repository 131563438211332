import { RequiredAccountConfigurationBankConciliations } from '../../models/special-document/ElementArgs'
import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'

export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface RegistersOfTrialBalanceFile {
  id?: number
  consignment_number?: string
  type_account?: string
  code?: number
  name?: string
  amount?: number
  amount_in_other_accounts?: number
  amount_per_statement?: number
  difference_amount?: number
} 

export interface TrialBalanceFileBankConciliations {
  id: number
  name: string
}

export class GeneralDataBankConciliationsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  async deleteFinding(engagement_id: number|null, documentId: number, findingId: number): Promise<BasicResponse> {
    const apiUrl = getUrl('finding_element_api/delete_finding/' + engagement_id + '/' + documentId + '/' + findingId)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async createFinding(engagement_id: number|null, documentId: number, elementId: number, reference: string | undefined): Promise<CreateResponse> {
    const apiUrl = getUrl('finding_element_api/create_finding/' + engagement_id + '/' + documentId + '/' + elementId + '/' + reference)
    try {
      const response = await this.fetcher(apiUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: CreateResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async getTrialBalanceSelected(engagementId: number|null| undefined, reference: string | undefined, documentId: number): Promise<number[]> {
    const apiUrl = getUrl('substantive_test_api/get_trial_balance_selected/' + engagementId+ '/' + reference + '/' + documentId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: number[] = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async updateAnswerSubstantiveTest(engagementId: number|null | undefined, documentId: number, answer: { reference: string | undefined, value: boolean }): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/update_answer_substantive_test/' + engagementId + '/' + documentId)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(answer)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }    

  async updateTrialBalanceFileSubstantiveTest(engagementId: number|null | undefined, documentId: number, data_trial_balance: { reference: string | undefined, trial_balance_id: number }): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/add_trial_balance_file_bank_conciliation/' + engagementId + '/' + documentId)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(data_trial_balance)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }  

  async getAnswerAndMaterialitySubstantiveTest(engagementId: number|null | undefined, reference: string | undefined, documentId: number): Promise<{materiality: number | undefined, answer: boolean | undefined, sample_quantity: number | undefined}> {
    const apiUrl = getUrl('substantive_test_api/get_answer_and_materiality_substantive_test/' + engagementId + '/' + reference+ '/' + documentId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data:  {materiality: number | undefined, answer: boolean | undefined, sample_quantity : number | undefined}= await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async getTrialBalanceFiles(engagementId: number|null| undefined, documentId: number): Promise<{data_trial_balance: TrialBalanceFileBankConciliations[], is_can_select: boolean}> {
    const apiUrl = getUrl('substantive_test_api/get_trial_balance_files/' + engagementId + '/' + documentId)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: {data_trial_balance: TrialBalanceFileBankConciliations[], is_can_select: boolean} = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }
}
