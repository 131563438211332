import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename } from "../../utils/utilities"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { AttachDocumentElement } from "../../models/special-document/AttachDocumentElement"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import DropzoneComponent from '../utils/Dropzone'
import EditModeProps from './EditModeProps'
import EditModeBase from './EditModeBase'

const AttachDocumentEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<AttachDocumentElement>(iElement as AttachDocumentElement).current
  const [isEditable, setIsEditable] = useState(false)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [title, setTitle] = useState(element.title)
  const [templateName, setTemplateName] = useState(element.templateName)

  useEffect(() => {
    if (element.state === "closed") {
      setIsEditable(false)
    }
  }, [])

  function handleConfiguration() {
    setShowConfigurationsModal(true)
  }

  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    element.title = title
    element.templateName = templateName

    const response = await elementRepository.saveElement("edition", element.args)
    if (!response) {
      setTitle(element.title)
      setTemplateName(element.templateName)
      window.htmlHelpers?.swalError()
    }
  }

  function handleConfigurationsModalCancel() {
    setShowConfigurationsModal(false)
  }

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={isEditable}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="card h-100 bg-light">
          <div className="card-body p-3">
            <h6>{title}</h6>
            <p>{T("Attachment")}: {decryptUniqueFilename(templateName)}</p>
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Attach Document")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <div>
          <div className="form-group">
            <label>{T("Title")}</label>
            <input
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>{T("Attachment")}</label>
            <DropzoneComponent
              elementId={element.id}
              attachmentName={element.templateName}
              onUpload={(templateName, _) => setTemplateName(templateName)}
            />
          </div>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default AttachDocumentEditMode;
