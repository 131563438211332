import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { Permissions } from '../../models/special-document/ElementBase'
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { QuestionJustifiedElement } from '../../models/special-document/QuestionJustifiedElement'
import type { ItemJustifedOption } from '../../models/special-document/ElementArgs'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import ViewModeProps from './ViewModeProps'
import ViewModeBase from './ViewModeBase'
import Summernote from '../utils/Summernote'

const QuestionJustifiedViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<QuestionJustifiedElement>(iElement as QuestionJustifiedElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [chosenOption, setChosenOption] = useState(element.chosenOption)
  const [itemsOnYes, setItemsOnYes] = useState(element.itemsOnYes)
  const [itemsOnNo, setItemsOnNo] = useState(element.itemsOnNo)
  const question = element.question

  function handleEdit() {
    setShowConfigurationsModal(true)
  }

  async function handleConfigurationsModalAccept() {
    setShowConfigurationsModal(false)
    const elementRepository = new ElementRepository()
    const lastChosenOption = element.chosenOption
    const lastItemsOnYes = element.itemsOnYes
    const lastItemsOnNo = element.itemsOnNo
    element.chosenOption = chosenOption
    element.itemsOnYes = itemsOnYes
    element.itemsOnNo = itemsOnNo

    let success = await elementRepository.saveElement("edition", element.args)
    if (!success) {
      element.chosenOption = lastChosenOption
      element.itemsOnYes = lastItemsOnYes
      element.itemsOnNo = lastItemsOnNo
      setChosenOption(lastChosenOption)
      setItemsOnYes(lastItemsOnYes)
      setItemsOnNo(lastItemsOnNo)
      window.htmlHelpers?.swalError()
    }
  }

  function handleConfigurationsModalCancel() {
    setItemsOnYes(element.itemsOnYes)
    setItemsOnNo(element.itemsOnNo)
    setChosenOption(element.chosenOption)
    setShowConfigurationsModal(false)
  }

  function onItemAnswerChange(index: number, value: string) {
    if (chosenOption === "yes") {
      itemsOnYes[index].answer = value
      setItemsOnYes([...itemsOnYes])
    } else {
      itemsOnNo[index].answer = value
      setItemsOnNo([...itemsOnNo])
    }
  }

  return (
    <>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <strong className="d-block mb-3 lh-sm">{question}</strong>
        {chosenOption !== "" ? (
          <ItemsPreview
            chosenOption={chosenOption}
            items={[itemsOnYes, itemsOnNo]}
          />
        ) : (<p>{T("No option has been selected")}</p>)}
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={question}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}
      >
        <div>
          <div className="mb-3">
            <label htmlFor="questionDropdown" className="form-label">
              {T("Select an Option")}
            </label>
            <select
              id="questionDropdown"
              className="form-select"
              value={chosenOption}
              onChange={(e) => setChosenOption(e.target.value)}
            >
              <option value="">{T("Please select")}</option>
              <option value="yes">{T("Yes")}</option>
              <option value="no">{T("No")}</option>
            </select>
          </div>
        </div>
        {chosenOption === "yes" && itemsOnYes && (
          <ItemsList items={itemsOnYes} onItemAnswerChange={onItemAnswerChange} />
        )}
        {chosenOption === "no" && itemsOnNo && (
          <ItemsList items={itemsOnNo} onItemAnswerChange={onItemAnswerChange} />
        )}
      </ModalWithButtons>
    </>
  );
}

interface ItemsPreviewProps {
  chosenOption: string
  items: ItemJustifedOption[][]
}

function ItemsPreview({ chosenOption, items }: ItemsPreviewProps) {
  const itemsToRender = chosenOption === "yes" ? items[0] : items[1]

  return (
    <>
      {itemsToRender.map((item, index) => (
        <div key={index} className="d-flex flex-column gap-2 mb-4">
          {item.title && <b className="m-0 fs-6">{item.title}</b>}
          <p className="mb-0">{item.text}</p>
          {item.type === "justification" && (
            <div className="d-flex flex-column">
              <span className="text-sm fw-bold">{T("Justification")}:</span>
              <div className="m-0" dangerouslySetInnerHTML={{ __html: item.answer ?? "" }} />
            </div>
          )}
        </div>
      ))}
    </>
  )
}


interface ItemsOnEditProps {
  items: ItemJustifedOption[]
  onItemAnswerChange: (index: number, value: string) => void
}

function ItemsList({ items, onItemAnswerChange }: ItemsOnEditProps) {
  return (
    <div className="mt-4">
      <h6>{T("Answers")}</h6>
      {
        items.map((item, index) => (
          <div key={index} className="d-flex flex-column gap-2">
            {item.title && <b className="m-0 fs-5">{item.title}</b>}
            <p className="m-0 text-wrap">{item.text}</p>
            {item.type === "justification" && (
              <Summernote
                value={item.answer ?? ""}
                onChange={(value) => onItemAnswerChange(index, value)}
                maxLength={2000}
              />
            )}
          </div>
        ))
      }
    </div>
  )
}

export default QuestionJustifiedViewMode;