import React, { useEffect, useState, useRef } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import { ConditionalParagraphOption,  } from '../../models/special-document/ElementArgs';
import ViewModeBase from "../special-document/ViewModeBase";
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { parseObject } from '../../utils/utilities';
import { ElementRepository } from '../../repository/special-document/ElementRepository';
import { GeneralDataBankConciliationsRepository } from '../../repository/special-document/GeneralDataBankConciliationsRepository';
import { BankConciliationsQuestionElement } from '../../models/substantive-tests/BankConciliationsQuestionElement';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter} from '../../components/utils/EventEmitter';


const QuestionBankConciliationsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<BankConciliationsQuestionElement>(iElement as BankConciliationsQuestionElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [optionIdSelected, setOptionIdSelected] = useState(element.optionIdSelected)
  const [optionSelected, setOptionSelected] = useState<ConditionalParagraphOption | undefined>(undefined)
  const [optionBaseSelected, setOptionBaseSelected] = useState<ConditionalParagraphOption | undefined>(undefined)
  const [refresh, setRefresh] = useState(false)
  const [key, setKey] = useState(0);

  useEffect(() => {
    setOptionIdSelected(element.optionIdSelected)
    if (element.optionIdSelected != null){
      setOptionSelected(JSON.parse(JSON.stringify(element.getOptionSelected())));
      setOptionBaseSelected(JSON.parse(JSON.stringify(element.getOptionSelected())));
    }
  }, [refresh]);

  const handleEdit = () => {
    setOptionIdSelected(element.optionIdSelected)
    if (element.optionIdSelected != null)
      setOptionSelected(parseObject(element.getOptionSelected()));
    setShowConfigurationsModal(true)
  };

  const handleEditModalAccept = async () => {
    setShowConfigurationsModal(false);
    const elementRepository = new ElementRepository()
    const lastOptionIdSelected = element.optionIdSelected
    const lastOptions = parseObject(element.options)
    element.optionIdSelected = optionIdSelected
    element.options.forEach((option) => {
      if (option.id === optionIdSelected) {
        option.message = optionSelected?.message ?? ""
        option.value = optionSelected?.value ?? ""
        option.editable = optionSelected?.editable ?? false
        option.has_attachment = optionSelected?.has_attachment ?? false
        option.attachment_name = optionSelected?.attachment_name ?? ""
      }
    })

    let success = await elementRepository.saveElement("view", element.args)
    if (success && optionSelected != undefined) {
      const questionBankConciliationsRepository = new GeneralDataBankConciliationsRepository()
      if (optionSelected.value == "Si") {
        const result = await questionBankConciliationsRepository.updateAnswerSubstantiveTest(element.args.engagement_id, element.args.document_id, {"reference": element.reference, "value": true})
      } else {
        const result = await questionBankConciliationsRepository.updateAnswerSubstantiveTest(element.args.engagement_id, element.args.document_id, {"reference": element.reference, "value": false})
      }
      setRefresh(!refresh)
      emitter.emit("refreshSubstantiveBalanceData");
    }

    if (!success) {
      element.optionIdSelected = lastOptionIdSelected
      element.options = lastOptions
      window.htmlHelpers?.swalError()
    }
  };

  const handleEditModalCancel = () => {
    setKey(prevKey => prevKey + 1);
    setOptionSelected(undefined)
    setShowConfigurationsModal(false)
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "") {
      setOptionIdSelected(null);
      setOptionSelected(undefined);
      return;
    }
    const value = parseInt(event.target.value, 10);
    setOptionIdSelected(value);
    setOptionSelected(parseObject(element.getOptionById(value)));
  };

  return (
    <div>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        {element.options.length === 0 &&
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{T("Without configuration")}</h6>
              <div className="chart pt-3">
                <p>{T("Configure this element from the template.")}</p>
              </div>
            </div>
          </div>
        }
        {element.options.length > 0 &&
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              <h6>{element.question}</h6>
              <div key={key} className="chart pt-3">
                {optionBaseSelected ? (
                  optionBaseSelected?.value
                ) : (
                  <p>{T("Select an answer.")}</p>
                )}
              </div>
            </div>
          </div>
        }
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("The client uses other asset or liability accounts to transfer amounts from reconciling items?")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <select key={key} value={optionIdSelected ?? ""} onChange={handleSelectChange} className="form-select">
          <option value="">{T("Select an answer.")}</option>
          {element.options.map((option) => (
            <option key={option.id} value={option.id} selected={option.id === element.optionIdSelected}>
              {T(option.value)}
            </option>
          ))}
        </select>
      </ModalWithButtons>
    </div>
  );
};

export default QuestionBankConciliationsViewMode;
