import React, { useEffect, useState } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from '../special-document/ViewModeProps';
import ViewModeBase from "../special-document/ViewModeBase";
import { ConditionalParagraphElementWithSelector } from '../../models/special-document/ConditionalParagraphElementWithSelector';
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { GeneralDataBankConciliationsRepository } from '../../repository/special-document/GeneralDataBankConciliationsRepository';
import { TrialBalanceFileBankConciliations } from '../../repository/special-document/GeneralDataBankConciliationsRepository';
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';


const TrialBalanceFileBankConciliationsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<ConditionalParagraphElementWithSelector>(iElement as ConditionalParagraphElementWithSelector)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [files, setFiles] = useState<TrialBalanceFileBankConciliations[]>([]);
  const [isCanSelect, setIsCanSelect] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [baseSelectedId, setBaseSelectedId] = useState<number>();
  const [key, setKey] = useState(0);

  useEffect(() => {
    const getFiles = async () => {
      const generalDataBankConciliationsRepository = new GeneralDataBankConciliationsRepository();
      const response_files = await generalDataBankConciliationsRepository.getTrialBalanceFiles(element.args.engagement_id, element.args.document_id);
      setFiles(response_files.data_trial_balance);
      setIsCanSelect(response_files.is_can_select);

      const response = await generalDataBankConciliationsRepository.getTrialBalanceSelected(element.args.engagement_id, element.reference, element.args.document_id);
      if (response != null) {
        setSelectedId(Number(response));
        setBaseSelectedId(Number(response));
      }
    }
    getFiles();

  }, [baseSelectedId]);

  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  const handleEditModalAccept = async () => {
    setShowConfigurationsModal(false);
    if (selectedId != undefined) {
      saveFile(selectedId);
      setBaseSelectedId(selectedId);
    }
  };


  const handleEditModalCancel = () => {
    setSelectedId(baseSelectedId)
    setKey(prevKey => prevKey + 1);
    setShowConfigurationsModal(false)
  };

  const saveFile = async (file_id: number) => {    
    const generalDataBankConciliationsRepository = new GeneralDataBankConciliationsRepository();
    const response = await generalDataBankConciliationsRepository.updateTrialBalanceFileSubstantiveTest(element.args.engagement_id, element.documentId, { "reference": element.reference, "trial_balance_id": file_id });
    if (response) emitter.emit("refreshSubstantiveBalanceData");
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedId(Number(event.target.value));
  };

  return (
    <div>
      <ViewModeBase
        isEditable={isCanSelect ? true : false}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        {isCanSelect ? (
          <div className="card h-100 bg-light">
            <div className="card-body p-3">
              {selectedId === undefined ? (
                <h6>{T("Without File")}</h6>
              ) : (
                <h6>{T("Selected File Correctly")}</h6>
              )}
              <div className="chart pt-3">
                <p>{T("Please select a trial balance. If you do not find the one you need, please go to the client view and upload the corresponding file.")}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="card h-100 mb-3 bg-warning">
            <div className="card-body p-3">
              <h6>{T("It is necessary to select the risk in order to proceed with the substantive test.")}</h6>
            </div>
          </div>
        )}
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Trial Balance File")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
          <div>
            <select key={key} value={selectedId} onChange={handleSelectChange} className="form-select">
              <option value={0}>{T("Select trial balance")}</option>
              {files.map(option => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
      </ModalWithButtons>
    </div>
  );
};
export default TrialBalanceFileBankConciliationsViewMode;
