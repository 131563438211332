import { ElementArgs, ConditionalParagraphOption } from "../special-document/ElementArgs";
import { IElementBase, ElementBase } from "../special-document/ElementBase";


export class BankConciliationsQuestionElement extends ElementBase implements IElementBase{
  _question: string
  _options: ConditionalParagraphOption[]
  _optionIdSelected: number | null = null
  constructor(args:ElementArgs) {
    super(args);
    this._question = args.question ?? ""
    this._optionIdSelected = args.optionIdSelected ?? null

    const opt = args.options as ConditionalParagraphOption[]
    if (Array.isArray(opt)) {
      // Filters the options to exclude those with the 'condition' property and handles the undefined case
      this._options = opt.filter((option) => !option.condition);
    } else {
      this._options = [];
    }
  }

  get question(): string {
    return this._question;
  }

  get options(): ConditionalParagraphOption[] {
    return this._options;
  }

  get optionIdSelected(): number | null {
    return this._optionIdSelected;
  }

  set question(question: string) {
    this._question = question;
    this.args.question = question;
  }

  set options(options: ConditionalParagraphOption[]) {
    this._options = options;
    this.args.options = options;
  }

  set optionIdSelected(optionIdSelected: number | null) {
    this._optionIdSelected = optionIdSelected;
    this.args.optionIdSelected = optionIdSelected;
  }

  getOptionSelected(): ConditionalParagraphOption | undefined {
    return this.options.find((option) => option.id === this.optionIdSelected);
  }

  getMessage(): string {
    const optionSelected = this.getOptionSelected();
    return optionSelected?.message ?? "";
  }

  getOptionById(id: number): ConditionalParagraphOption | undefined {
    return this.options.find((option) => option.id === id);
  }

  render(): void {
    console.log("render conditional paragraph element with selector")
  }
}