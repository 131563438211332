import { ElementBase, IElementBase } from "../special-document/ElementBase"
import { ElementArgs } from "../special-document/ElementArgs"


export class SubstantiveTestBankConciliationsElement extends ElementBase implements IElementBase {

  constructor(args: ElementArgs) {
    super(args)
  }

  render(): void {
    console.log("rendering substantive test bank conciliations element")
  }
}