import React from 'react'
import ViewModeProps from './ViewModeProps';


/**
* Renders the internal control components configuration view mode.
* @param {ViewModeProps} props - The component properties.
*/
const InternalControlComponentsConfigurationViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  return null
};

export default InternalControlComponentsConfigurationViewMode;
