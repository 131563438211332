import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename } from "../../utils/utilities"
import { type Permissions } from '../../models/special-document/ElementBase'
import { AttachDocumentElement } from "../../models/special-document/AttachDocumentElement"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ElementAttachmentRepository } from "../../repository/special-document/ElementAttachmentRepository"
import DropzoneComponent from '../utils/Dropzone'
import ViewModeProps from './ViewModeProps'
import ViewModeBase from "./ViewModeBase"

const AttachDocumentViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<AttachDocumentElement>(iElement as AttachDocumentElement).current
  const [attachmentName, setAttachmentName] = useState(element.attachmentName)

  async function handleDownloadAttachment() {
    const elementAttachmentRepository = new ElementAttachmentRepository();
    const attachment = await elementAttachmentRepository.getAttachment(element.id, element.templateName);
    const url = window.URL.createObjectURL(attachment);
    const a = document.createElement('a');
    a.href = url;
    a.download = decryptUniqueFilename(element.templateName);
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  async function handleRemoveAttachment() {
    const elementAttachmentRepository = new ElementAttachmentRepository()
    const response = await elementAttachmentRepository.removeAttachment(element.id, attachmentName)
    if (!response) {
      return window.htmlHelpers?.customSwalError(T("Either the file does not exist or you do not have permission to delete it"))
    }

    const elementRepository = new ElementRepository()
    element.attachmentName = ""
    const response2 = await elementRepository.saveElement("view", element.args)
    if (!response2) {
      setAttachmentName(element.attachmentName)
      return window.htmlHelpers?.swalError()
    }
    
    setAttachmentName("")
  }

  async function handleSavingAttachment() {
    const elementRepository = new ElementRepository()
    element.attachmentName = attachmentName
    const response = await elementRepository.saveElement("view", element.args)
    if (!response) {
      setAttachmentName(element.attachmentName)
      window.htmlHelpers?.swalError()
    }
  }

  useEffect(() => {
    handleSavingAttachment()
  }, [attachmentName])

  return (
    <ViewModeBase
      isEditable={false}
      permissions={element.permissions as Permissions}
    >
      <div className="card h-100 bg-light">
        <div className="card-body p-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="w-100">
              <h6>{element.title}</h6>
              <div className="d-flex justify-content-between align-items-center w-100">
                <p className="m-0">{T("Template")}: <em>{decryptUniqueFilename(element.templateName)}</em></p>
                <button
                  type="button"
                  style={{ cursor: 'pointer' }}
                  className="d-flex align-items-center gap-2 p-1 bg-transparent border-0 text-secondary"
                  onClick={() => handleDownloadAttachment()}
                >
                  <i className="fa-solid fa-download"></i>
                  <span>{T("Download file")}</span>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-4">
            {
              attachmentName ? (
                <div className="d-flex justify-content-between align-items-center w-100">
                  <p className="m-0">{T("Attachment")}: <em>{decryptUniqueFilename(attachmentName)}</em></p>
                  <button
                    type="button"
                    style={{ cursor: 'pointer' }}
                    className="d-flex align-items-center gap-2 p-1 bg-transparent border-0 text-secondary"
                    onClick={() => handleRemoveAttachment()}
                  >
                    <i className="fa-solid fa-trash"></i>
                    <span>{T("Remove file")}</span>
                  </button>
                </div>
              )
                : (
                  <>
                    <h6>{T("Upload file")}</h6>
                    <DropzoneComponent elementId={element.id} onUpload={(attachmentName) => setAttachmentName(attachmentName)} />
                  </>
                )
            }
          </div>
        </div>
      </div>
    </ViewModeBase>
  );
};

export default AttachDocumentViewMode;
