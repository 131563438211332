import { getUrl, joinURL } from '@/utils/app-config'
import { fetchAPI, ResponseBody } from '@/services/api_services/fetchAPI'
import { FindingsEvaluationOptions, Strategy, SubstantiveTestStrategy } from '@/models/special-document/ElementArgs'

export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}


/**
 * Repository for Transaction Flow Strategy
 * @param fetcher
 */
export class TransactionFlowStrategyRepository {
  private _basePath = 'transaction_flow_strategy_api'
  private _jsonContentType = { 'Content-Type': 'application/json' }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)) { }

  /**
   * Retrieves transaction flow strategies for the given engagement and element in a specific rendering mode.
   * @param engagementId - The engagement identifier, can be null.
   * @param elementId - The element identifier.
   * @param renderingMode - The mode in which strategies should be rendered.
   * @returns A promise that resolves with an object containing the success flag and an array of strategies.
   */
  async getTransactionFlowStrategiesInView(
    engagementId: number | null, elementId: number, renderingMode: string
  ): Promise<{ success: boolean, data: Strategy[] }> {
    const apiUrl = getUrl(joinURL(this._basePath, 'get_transaction_flows_strategy_in_view', engagementId, elementId, renderingMode))
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data = await response.json() as { success: boolean, data: Strategy[] };
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  /**
   * Creates a new transaction flow strategy.
   * @param engagementId - The engagement identifier, can be null.
   * @param elementId - The element identifier.
   * @param renderingMode - The mode in which strategies should be rendered.
   * @param strategy - The strategy to be created.
   * @returns A promise that resolves with the response body.
   */
  async updateStrategyDescription(
    engagementId: number, elementId: number, renderingMode: string, strategy_id: number, description: string
  ): Promise<{ success: boolean }> {
    const apiUrl = getUrl(joinURL(this._basePath, 'update_strategy_description', engagementId, elementId, renderingMode))
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ strategy_id, description })
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }

  /**
   * Creates a new transaction flow strategy.
   * @param engagementId - The engagement identifier, can be null.
   * @param elementId - The element identifier.
   * @param renderingMode - The mode in which strategies should be rendered.
   * @param strategy - The strategy to be created.
   * @returns A promise that resolves with the response body.
   */
  async createSubstantiveProcedure(
    engagementId: number | null, elementId: number, renderingMode: string, controlId: number, procedure: SubstantiveTestStrategy
  ): ResponseBody<number | null> {
    const apiUrl = getUrl(joinURL(this._basePath, 'add_substantive_procedure', engagementId, elementId, renderingMode))
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({
            control_id: controlId,
            substantive_test_name: procedure.substantive_test_name,
            is_mandatory: procedure.is_mandatory,
          })
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false, data: null }
    }
  }

  /**
   * Retrieves transaction flow strategies for the given engagement and element in a specific rendering mode.
   * @param engagementId - The engagement identifier, can be null.
   * @param elementId - The element identifier.
   * @param renderingMode - The mode in which strategies should be rendered.
   * @returns A promise that resolves with an object containing the success flag and an array of strategies.
   */
  async deleteSubstantiveProcedure(
    engagementId: number | null, elementId: number, renderingMode: string, control_id: number, substantive_id: number): Promise<{ success: boolean }> {
    const apiUrl = getUrl(joinURL(this._basePath, 'delete_substantive_procedure', engagementId, elementId, renderingMode))
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify({ control_id, substantive_id })
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      return await response.json()
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      return { success: false }
    }
  }
}
