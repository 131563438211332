import React, { useEffect, useState } from 'react';
import { T } from "../../utils/i18n-config"
import DOMPurify from 'isomorphic-dompurify';
import Swal from 'sweetalert2';
import { Constants } from '../../utils/Constants';
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';
import { RegistersMemorandumComplexityTechnologyFirstStageArgs, MemorandumComplexityTechnologyControlsRepository } from '../../repository/special-document/MemorandumComplexityTechnologyControlsRepository';


export interface RegisterType {
  id: string;
  reference_id: number;
  name_reconciling_items: string;
  extracted_value: string;
}


/**
 * Component for displaying the memorandum complexity technology controls in view mode.
 * @param {ViewModeProps} props - The properties for the component, including element data.
 */
const MemorandumComplexityTechnologyControlsViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [changes, setChanges] = useState<Record<number, Partial<RegistersMemorandumComplexityTechnologyFirstStageArgs>>>({});
  const [changesFirstRegister, setChangesFirstRegister] = useState<Record<number, Partial<RegistersMemorandumComplexityTechnologyFirstStageArgs>>>({});
  const [baseRegisters, setBaseRegisters] = useState<RegistersMemorandumComplexityTechnologyFirstStageArgs[]>([]);
  const [firstRegister, setFirstRegister] = useState<RegistersMemorandumComplexityTechnologyFirstStageArgs | null>(null);
  const [anyTestControls, setAnyTestControls] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [key, setKey] = useState(0);

  const answerYesNo = [
    { id: 1, name: T("Yes") },
    { id: 2, name: T("No") },
  ];

  /**
   * Fetches the registers for complexity technology environment.
   */
  const getRegistersComplexityTechnologyEnvironment = async () => {
    const memorandumComplexityTechnologyControlsRepository = new MemorandumComplexityTechnologyControlsRepository()
    const result = await memorandumComplexityTechnologyControlsRepository.getMemorandumComplexityTechnologyEnvironmentByStage(element.args.engagement_id, element.id, 1)

    if (result.success) {    
      const hasAnyTestControls = result.data.some((item) => item.is_test_controls === true);
      setAnyTestControls(hasAnyTestControls);
      const firstRegister = result.data[0];
      setFirstRegister(firstRegister);
      const skippedData = result.data.slice(1);

      const initialFirstRegister = {
        [firstRegister.id]: {
          value_yes_no: firstRegister.value_yes_no,
          value_description: firstRegister.value_description,
        }
      };
      
      setChangesFirstRegister(initialFirstRegister);
      const initialChanges = skippedData.reduce((acc, register) => {
        acc[register.id] = {
          value_yes_no: register.value_yes_no,
          value_description: register.value_description,
        };
        return acc;
      }, {} as Record<number, Partial<RegistersMemorandumComplexityTechnologyFirstStageArgs>>);

      setChanges(initialChanges);
      setBaseRegisters(result.data);
    } else {
      setBaseRegisters([]);
    }
  }

  useEffect(() => {
    getRegistersComplexityTechnologyEnvironment()
  }, [isRefreshLocal]);

  /**
   * Handles the event of opening the configuration modal.
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  /**
   * Handles the acceptance of the edit modal, updating the memorandum complexity technology environment.
   */
  const handleEditModalAccept = async () => {
    const memorandumComplexityTechnologyControlsRepository = new MemorandumComplexityTechnologyControlsRepository()
    const result = await memorandumComplexityTechnologyControlsRepository.updateMemorandumComplexityTechnologyEnvironmentByStage(element.args.engagement_id, element.args.document_id, element.id, changes)
    setIsRefreshLocal(prev => !prev);
    setChanges({});
    if (result.success) {
      setShowConfigurationsModal(false);
    }
  };

  /**
   * Handles the cancellation of the edit modal.
   */
  const handleEditModalCancel = () => {
    setIsRefreshLocal(prev => !prev);
    setShowConfigurationsModal(false)
    setKey(prevKey => prevKey + 1);
  };

  /**
   * Handles the change in the first question.
   * @param {number} itemId - The ID of the register item.
   * @param {keyof RegistersMemorandumComplexityTechnologyFirstStageArgs} field - The field to update.
   * @param {number | string | undefined | null} value - The new value for the field.
   */
  const handleInputChange = (itemId: number , field: keyof RegistersMemorandumComplexityTechnologyFirstStageArgs, value: number | string | undefined | null) => {
    setChanges(prev => ({
      ...prev,
      [itemId]: { ...prev[itemId], [field]: value }
    }));
    
  };

  /**
   * Handles the change in the first question.
   */
  const handleFirstQuestionChange = async (itemId: number, field: keyof RegistersMemorandumComplexityTechnologyFirstStageArgs, value: number | string | undefined | null) => {
    const updatedRegister = {
      ...changesFirstRegister,
      [itemId]: { 
          ...(changesFirstRegister[itemId] || {}), 
          [field]: value 
      }
  };
    const memorandumComplexityTechnologyControlsRepository = new MemorandumComplexityTechnologyControlsRepository()
    const result = await memorandumComplexityTechnologyControlsRepository.updateMemorandumComplexityTechnologyEnvironmentByStage(element.args.engagement_id, element.args.document_id, element.id, updatedRegister)
    setIsRefreshLocal(prev => !prev);
    emitter.emit("refreshComplexityTechnologyControls")
  };

  /**
   * Marks a register as changed.
   * @param {number } itemId - The ID of the item.
   * @param {number} transactionFlowId - The ID of the transaction flow.
   */
  const markChanged = async (itemId: number , transactionFlowId: number) => {
    const memorandumComplexityTechnologyControlsRepository = new MemorandumComplexityTechnologyControlsRepository()
    const result = await memorandumComplexityTechnologyControlsRepository.changeCurrentStatusConclusion(element.args.engagement_id, element.args.document_id, element.id, itemId, transactionFlowId)
    setIsRefreshLocal(prev => !prev);
    emitter.emit("refreshComplexityTechnologyControls")
  }

  /**
   * Handles the deletion of a transaction flow.
   * @param {number} itemId - The ID of the item.
   * @param {number} transactionFlowId - The ID of the transaction flow.
   */
  const handleDeleteChange = (itemId: number, transactionFlowId: number) => {
    Swal.fire({
      title: T('Do you want to remove the transaction flow?'),
      text: T('It will be permanently removed.'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: T('Yes'),
      cancelButtonText: T('No')
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTransactionFlow(itemId, transactionFlowId);
      }
    });
  }

  /**
   * Deletes a transaction flow.
   * @param {number} itemId - The ID of the item.
   * @param {number} transactionFlowId - The ID of the transaction flow.
   */
  const deleteTransactionFlow = async (itemId: number, transactionFlowId: number) => {
    if (baseRegisters.length > 1) {
      const memorandumComplexityTechnologyControlsRepository = new MemorandumComplexityTechnologyControlsRepository()
      const result = await memorandumComplexityTechnologyControlsRepository.deleteMemorandumComplexityTransactionFlows(element.args.engagement_id, element.args.document_id, element.id, itemId, transactionFlowId)
      setIsRefreshLocal(prev => !prev);
      emitter.emit("refreshComplexityTechnologyControls")
    }
  };

  return (
    <div key={key}>
      <ViewModeBase
        isEditable={true}
        handleEdit={handleEdit}
        permissions={element.permissions as Permissions}
      >
        <div key={key} className="d-flex w-full flex-column mb-3">
          <div className="mb-3 w-100">
            <div className="w-100">
              {baseRegisters.length > 0 && (
                <>
                  <h6>{baseRegisters[0].title_name}</h6>
                  <p>{baseRegisters[0].question}</p>
                  {firstRegister && Number(changesFirstRegister[firstRegister.id]?.value_yes_no) === 1 ? (
                    <>
                      <p><em>{T("Yes")}</em></p>
                      <p><em>{T("After conducting the analysis of the IT environment, it has been determined that its level of complexity is high. Consequently, the involvement of IT experts is considered necessary, in alignment with the selected audit approach.")}</em></p>
                    </>
                  ) : firstRegister && Number(changesFirstRegister[firstRegister.id]?.value_yes_no) === 2 ? (
                    <>
                      <p><em>{T("No")}</em></p>
                      <p><em>{T("After carrying out the analysis of the IT environment, it has been determined that its level of complexity is low. Consequently, the involvement of IT experts is not considered necessary, in line with the selected audit approach.")}</em></p>
                    </>
                  ) : <p><em>{T("No response")}</em></p>
                  }
                </>
              )}
              {firstRegister && Number(changesFirstRegister[firstRegister.id]?.value_yes_no) === 1 && (
                <>
                  <h6>{T("Association of applications with the transaction flows and risk assessments")}</h6>
                  <p>{T("Once the process of recording accounting information in the entity's information systems is understood, it associates each transaction flow to the corresponding application:")}</p>
                  <div className="d-flex justify-content-center">
                    <table className="table-bordered w-100">
                      <thead>
                        <tr>
                          <th className="w-25 p-2">{T("Transaction Flow")}</th>
                          <th className="w-25 p-2">{T("Audit strategy")}</th>
                          <th className="w-25 p-2">{T("Application")}</th>
                          <th className="w-25 p-2">{T("Conclusion")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          onClick={() => setIsExpanded(!isExpanded)}
                          className="text-center"
                          style={{ backgroundColor: "#f8f9fa", cursor: "pointer", fontWeight: "bold" }}
                        >
                          <td colSpan={4} className="p-2">
                            {T("Expand/Collapse Records")}
                          </td>
                        </tr>
                        {isExpanded &&
                          baseRegisters.slice(1).map((item, index) => (
                            <tr key={item.id}>
                              <td className="p-1">{index === baseRegisters.slice(1).length - 1 ? T(item.subtitle_name || "") : T(item.subtitle_name || "")}
                              </td>
                              <td className="p-1">{item.strategy}</td>
                              <td className="p-1">
                                <div
                                  className={`${Constants.CONTENT_CLASS}`}
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(item.value_description || ""),
                                  }}
                                />
                              </td>
                              <td className="p-1">
                                {item.subtitle_name !== "Accounting" ? (

                                <div className="d-flex flex-column align-items-center">
                                  {item.is_test_controls === true ? T("Test controls") : T("No test controls")}
                                  {item.was_changed === true && (
                                    <span className="badge badge-warning mt-2">{T("Changed")}</span>
                                  )}
                                </div>
                                ) : (
                                  anyTestControls ? (
                                    T("Test controls")
                                  ) : (
                                    T("No test controls")
                                  )
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </ViewModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Memorandum Complexity Technology")}
        size="xl"
        onAccept={handleEditModalAccept}
        onCancel={handleEditModalCancel}>
        <div>
          <div style={{ width: "100%" }}>
            <div className="mb-3">
              {baseRegisters.length > 0 && (
                <>
                  <div className="mb-3">
                    <h4>{baseRegisters[0].title_name}</h4>
                    <p>{baseRegisters[0].question}</p>
                    <label htmlFor="firstItemSelect">{T("Select")}</label>
                    <select
                      id="firstItemSelect"
                      className="form-control"
                      value={firstRegister ? changesFirstRegister[firstRegister.id]?.value_yes_no || "" : ""}
                      onChange={(e) => firstRegister && handleFirstQuestionChange(firstRegister.id, 'value_yes_no', e.target.value)}
                    >
                      <option value="" disabled>
                        {T("Select an option")}
                      </option>
                      {answerYesNo.map((item) => (
                        <option key={`option-${item.id}`} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {firstRegister && Number(changesFirstRegister[firstRegister.id]?.value_yes_no) === 1 ? (
                      <p>{T("After conducting the analysis of the IT environment, it has been determined that its level of complexity is high. Consequently, the involvement of IT experts is considered necessary, in alignment with the selected audit approach.")}</p>
                    ) : firstRegister && Number(changesFirstRegister[firstRegister.id]?.value_yes_no) === 2 ? (
                      <p>{T("After carrying out the analysis of the IT environment, it has been determined that its level of complexity is low. Consequently, the involvement of IT experts is not considered necessary, in line with the selected audit approach.")}</p>
                    ) : null}

                  </div>
                  {firstRegister && Number(changesFirstRegister[firstRegister.id]?.value_yes_no) === 1 && (
                    <div className="d-flex justify-content-center">
                      <table className="table-bordered w-90">
                        <thead>
                          <tr>
                            <th className="w-25 p-2">{T("Transaction Flow")}</th>
                            <th className="w-20 p-2">{T("Audit strategy")}</th>
                            <th className="w-25 p-2">{T("Application")}</th>
                            <th className="w-25 p-2">{T("Conclusion")}</th>
                            <th className="w-5 p-2">{T("Actions")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {baseRegisters.slice(1).map((item, index) => (
                            <tr key={`${item.id}-${key}`}>
                              <td className="p-1">{index === baseRegisters.slice(1).length - 1 ? T(item.subtitle_name || "") : item.subtitle_name}</td>
                              <td className="p-1">{item.strategy}</td>
                              <td className="p-1">
                                <textarea
                                  className="form-control"
                                  defaultValue={item.value_description || ""}
                                  onChange={e => handleInputChange(item.id, 'value_description', e.target.value)}
                                />
                              </td>
                              <td className="p-1">
                              {item.subtitle_name !== "Accounting" ? (
                                <div className="d-flex flex-column align-items-center">
                                  {item.is_test_controls === true ? (
                                    T("Test controls")
                                  ) : (
                                    T("No test controls")
                                  )}
                                  {item.was_changed === true && (
                                    <span className="badge badge-warning mt-2">
                                      {T("Changed")}
                                    </span>
                                  )}
                                  
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => markChanged(item.id, item.transaction_flow_id)}
                                  >
                                    {T("Change")}
                                  </button>
                                </div>
                              ) : (
                                anyTestControls ? (
                                  T("Test controls")
                                ) : (
                                  T("No test controls")
                                )
                              )}
                              </td>
                              <td className="text-center">
                                {item.subtitle_name !== "Accounting" && (
                                  <button
                                    type="button"
                                    className="bg-transparent border-0 text-danger"
                                    onClick={() => handleDeleteChange(item.id, item.transaction_flow_id)}
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div>
            <hr />
          </div>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default MemorandumComplexityTechnologyControlsViewMode;
