import React, { useEffect, useState } from 'react';
import { T } from "../../utils/i18n-config"
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";
import { SubstantiveTestBankConciliationsElement } from '../../models/substantive-tests/SubstantiveTestBankConciliationsElement';
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { Permissions } from '../../models/special-document/ElementBase'
import { emitter } from '../utils/EventEmitter';
import { RegistersMemorandumComplexityTechnologyFirstStageArgs, MemorandumComplexityTechnologyControlsRepository } from '../../repository/special-document/MemorandumComplexityTechnologyControlsRepository';


export interface RegisterType {
  id: string;
  reference_id: number;
  name_reconciling_items: string;
  extracted_value: string;
}


/**
 * Component for displaying the memorandum complexity technology conclusion in view mode.
 * @param {ViewModeProps} props - The properties for the component, including element data.
 */
const MemorandumComplexityTechnologyConclusionViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const [element, setElement] = useState<SubstantiveTestBankConciliationsElement>(iElement as SubstantiveTestBankConciliationsElement)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [isRefreshLocal, setIsRefreshLocal] = useState(false);
  const [changes, setChanges] = useState<Record<number, Partial<RegistersMemorandumComplexityTechnologyFirstStageArgs>>>({});
  const [title, setTitle] = useState<string>("");
  const [baseRegisters, setBaseRegisters] = useState<RegistersMemorandumComplexityTechnologyFirstStageArgs[]>([]);
  const [isFinished, setIsFinished] = useState<boolean>(true);
  const [referenceValidation, setReferenceValidation] = useState<string | undefined>(undefined);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const handleEvent = () => {
      setIsRefreshLocal(prev => !prev);
      setKey(prevKey => prevKey + 1);
    };
    emitter.on("refreshComplexityTechnologyControls", handleEvent);
    return () => {
      emitter.off("refreshComplexityTechnologyControls", handleEvent);
    };
  }, []);

  /**
   * Fetches the registers for complexity technology environment.
   */
  const getRegistersComplexityTechnologyEnvironment = async () => {
    const memorandumComplexityTechnologyControlsRepository = new MemorandumComplexityTechnologyControlsRepository()
    const result = await memorandumComplexityTechnologyControlsRepository.getMemorandumComplexityTechnologyEnvironmentByStage(element.args.engagement_id, element.id, 5)
    if (result.success) {
      setTitle(result.data[0].title_name ?? "");
      setBaseRegisters(result.data);
    } else {
      setBaseRegisters([]);
      setTitle("");
    }

    const resultValidation = await memorandumComplexityTechnologyControlsRepository.getValidationControlsComplexityTechnology(element.args.engagement_id, element.id)
      
      if (resultValidation.success) {
        setReferenceValidation(resultValidation.reference);
        setIsFinished(resultValidation.is_finished);
      } else {
        setReferenceValidation(undefined);
        setIsFinished(false);
      }
  }

  useEffect(() => {
    getRegistersComplexityTechnologyEnvironment()
  }, [isRefreshLocal]);

  /**
   * Handles the event of opening the configuration modal.
   */
  const handleEdit = () => {
    setShowConfigurationsModal(true)
  };

  /**
   * Handles the event of accepting the configuration modal.
   */
  const handleEditModalAccept = async () => {
    const memorandumComplexityTechnologyControlsRepository = new MemorandumComplexityTechnologyControlsRepository()
    const result = await memorandumComplexityTechnologyControlsRepository.updateMemorandumComplexityTechnologyEnvironmentByStage(element.args.engagement_id, element.args.document_id, element.id, changes)
    setIsRefreshLocal(prev => !prev);
    setChanges({});
    if (result.success) {
      setShowConfigurationsModal(false);
    }
  };

  /**
   * Handles the event of canceling the configuration modal.
   */
  const handleEditModalCancel = () => {
    setIsRefreshLocal(prev => !prev);
    setShowConfigurationsModal(false)
    setKey(prevKey => prevKey + 1);
  };

  /**
   * Handles the input change event for a specific field in the register.
   * @param {number } itemId - The ID of the item being edited.
   * @param {keyof RegistersMemorandumComplexityTechnologyFirstStageArgs} field - The field being edited.
   * @param {number | string | undefined | null} value - The new value for the field.
   */
  const handleInputChange = (itemId: number , field: keyof RegistersMemorandumComplexityTechnologyFirstStageArgs, value: number | string | undefined | null) => {
    setChanges(prev => ({
      ...prev,
      [itemId]: { ...prev[itemId], [field]: value }
    }));
  };

  return (

    <div key={key}>
      {(!isFinished && referenceValidation !== "all_no_test_controls") ? (
        <>
          <ViewModeBase
            isEditable={true}
            handleEdit={handleEdit}
            permissions={element.permissions as Permissions}
          >
            <div key={key} className="d-flex w-full flex-column mb-3">
              <div className="mb-3 w-100">
                <h4>{T(title)}</h4>
                <div className="w-100">
                  {baseRegisters.map((item, index) => (
                    <React.Fragment key={item.id || index}>
                      <div className="form-group">
                        <label>{item.question}</label>
                        <p>{item.value_description}</p>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </ViewModeBase>
          <ModalWithButtons
            showModal={showConfigurationsModal}
            title={T("Memorandum Complexity Technology")}
            size="xl"
            onAccept={handleEditModalAccept}
            onCancel={handleEditModalCancel}>
            <div>
              <div style={{ width: "100%" }}>
                <div className="mb-3">
                <h4>{T(title)}</h4>
                  <div className="d-flex flex-column w-100">
                    {baseRegisters.map((item) => (
                      <div className="w-100">
                        <div className="form-group">
                          <label>{item.question}</label>
                          <textarea
                            className="form-control"
                            defaultValue={item.value_description || ""}
                            onChange={(e) => handleInputChange(item.id, "value_description", e.target.value)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <hr />
              </div>
            </div>
          </ModalWithButtons>
        </>
      ) : (
        <React.Fragment>
          <h4>{T("Conclusion")}</h4>
          <p>{T("After carrying out the analysis of the IT environment, it has been determined that its level of complexity is low. Consequently, the involvement of IT experts is not considered necessary, in line with the selected audit approach.")}</p>
        </React.Fragment>
      )}
    </div>
  );
};

export default MemorandumComplexityTechnologyConclusionViewMode;
