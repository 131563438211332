import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { type Permissions } from "../../models/special-document/ElementBase"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { RedirectionButtonElement } from "../../models/special-document/RedirectionButtonElement"
import { formatCurrency } from '../../utils/utilities';
import ViewModeProps from './ViewModeProps'
import ViewModeBase from "./ViewModeBase"

interface Balance {
  value?: number;
  period?: string;
}

export interface Account {
  code: string;
  name: string;
  balances: Balance[];
  subaccounts?: Account[];
}

interface AccordionProps {
  account: Account;
}


/**
 * Component to show the opening balances of the trial balance
 * @param ViewModeProps - interface with the element
 */
const TrialBalanceOpeningBalanceViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<RedirectionButtonElement>(iElement as RedirectionButtonElement).current
  const [registersOpeningBalances, setRegistersOpeningBalances] = useState<Account | undefined>(undefined)
  const [notVarianceAnalysis, setNotVarianceAnalysis] = useState(true)
  const [notRequired, setNotRequired] = useState(true)
  const [key, setKey] = useState(0)

  useEffect(() => {
    /**
     * Fetch the redirection URL from the API and set it to the state
     */
    async function fetchRedirectionUrl() {
      const elementRepository = new ElementRepository()
      const result = await elementRepository.validateOpeningBalancesDocument(
        element.engagement_id, element.documentId, element.id
      )
      
      if (result.success) {
        if (result.no_variance_analysis === true) {
          setNotVarianceAnalysis(false)
        }
        if (result.data) {
          setRegistersOpeningBalances(result.data)
        } else {
          setRegistersOpeningBalances(undefined)
        }
        
        if (result.is_not_required === true) {          
          setNotRequired(false)          
        }
        setKey(prev => prev + 1)
      }
    }

    fetchRedirectionUrl()
  }, [])

  useEffect(() => {
    setKey(prev => prev + 1)
  }, [notRequired])

  /**
   * This function is used to handle the redirection of the trial balance
   */
  const handleRedirection = () => {
    const url = `/audix/engagement_select_trial_balances/index.load/${element.engagement_id}?is_opening_balances=True`;
    window.menuService?.breadcrumbAddLevel(url, T('Trial balances'))
  }

  const balanceHeaders = [T("Annex Balance December"), T("Annex Balance January")];

  return (
    <div key={key}>
      {notRequired ? (
        <div className="card h-100 bg-light">
          <div className="card-body p-3">
            <h6>{T("There is no requirement to test opening balances.")}</h6>
          </div>
        </div>
      ) : (
        <React.Fragment>
          {notVarianceAnalysis ? (
            <div className="card h-100 bg-light">
              <div className="card-body p-3">
                <h6>{T("It is necessary to complete the first stage of the analysis of variance.")}</h6>
              </div>
            </div>
          ) : (
            <ViewModeBase
              isEditable={false}
              permissions={element.permissions as Permissions}
            >
              {(!registersOpeningBalances || !registersOpeningBalances.subaccounts) ? (
                <React.Fragment>
                  {element.args.engagement_id ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleRedirection}
                    >
                      {T("Select Trial Balance")}
                    </button>
                  ) : (
                    <div className="card h-100 bg-light">
                      <div className="card-body p-3">
                        <h6>{T("Display component for selecting trial balance for opening balances")}</h6>
                      </div>
                    </div>
                  )}
                </ React.Fragment>
              ) : (
                registersOpeningBalances.subaccounts.length === 0 ? (
                  <div className="card h-100">
                    <div className="card-body p-3">
                      <h6>{T("The correct transfer of the opening balances for the current period has been validated, concluding that the information from the previous period was accurately and appropriately carried over to the current period.")}</h6>
                    </div>
                  </div>
                ) : (<div style={{ padding: "1rem" }}>
                  <h2>{T("Accounts")}</h2>
                  <p>{T("Differences have been identified between the opening balances of the current period and the closing balances of the previous period. Consequently, it is necessary to proceed with the reconciliation of the following accounts:")}</p>
                  <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ddd" }}>
                    <thead>
                      <tr>
                        <th style={{ border: "1px solid #ddd", padding: "0.5rem", width: "20%" }}>{T("Code")}</th>
                        <th style={{ border: "1px solid #ddd", padding: "0.5rem", width: "30%" }}>{T("Name")}</th>
                        {balanceHeaders.map((header, index) => (
                          <th key={index} style={{ border: "1px solid #ddd", padding: "0.5rem", width: "25%" }}>
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {registersOpeningBalances.subaccounts.map((account) => (
                        <AccountAccordion key={account.code} account={account} />
                      ))}
                    </tbody>
                  </table>
                </div>
                )
              )}
            </ViewModeBase>
          )}
        </ React.Fragment>
      )}
    </div>
  );
};

export default TrialBalanceOpeningBalanceViewMode;


/**
 * Component to show the account accordion
 * @param AccordionProps - interface with the account
 */
const AccountAccordion: React.FC<AccordionProps> = ({ account }) => {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * This function is used to toggle the accordion
   */
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <tr>
        <td>
          <button
            onClick={toggleAccordion}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              fontWeight: isOpen ? "bold" : "normal",
            }}
          >
            <i className={isOpen ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i> {account.code}
          </button>
        </td>
        <td>{account.name}</td>
        {account.balances?.map((balance, index) => (
          <td key={index}>{formatCurrency(balance.value || 0)}</td>
        ))}
      </tr>
      {isOpen &&
        account.subaccounts?.map((subaccount) => (
          <React.Fragment key={subaccount.code}>
            <AccountAccordion account={subaccount} />
          </React.Fragment>
        ))}
    </ React.Fragment>
  );
};

