import React, { useState, useEffect, useRef } from 'react'
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename } from "../../utils/utilities"
import { type Permissions } from "../../models/special-document/ElementBase"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { ItemOpeningBalancesElement } from "../../models/special-document/ItemOpeningBalancesElement"
import ViewModeProps from './ViewModeProps'
import DropzoneComponent from "../utils/Dropzone"
import ViewModeBase from "./ViewModeBase"
import ModalWithButtons from '../modals/AceptCancelModalBase'
import { ItemsOpeningBalances } from '../../models/special-document/ElementArgs'


/**
 * Component to show the opening balances of the items
 * @param ViewModeProps - interface with the element
 */
const ItemOpeningBalancesViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<ItemOpeningBalancesElement>(iElement as ItemOpeningBalancesElement).current
  const [itemOpeningBalances, setItemOpeningBalances] = useState<ItemsOpeningBalances[]>(element.items_opening_balances)
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [notRequired, setNotRequired] = useState(true)
  const [accountsStatus, setAccountsStatus] = useState<{ [key: string]: boolean }>({})
  const [noMateriality, setNoMateriality] = useState(false)
  const [typeConclusionSelected, setTypeConclusionSelected] = useState<number | undefined>(element.type_conclusion)
  const [key, setKey] = useState(0)

  const answerYesNo = [
    { id: 1, name: T("Yes") },
    { id: 2, name: T("No") }
  ]

  useEffect(() => {
    const elementRepository = new ElementRepository()
    const fetchData = async () => {
      const data = await elementRepository.validateEngagementConfigurationOpeningBalancesDocument(element.engagement_id, element.documentId, element.id)

      if (data.success) {
        if (data.no_materiality === true) {
          setNoMateriality(true)
        }
        if (data.is_not_required === true) {

          setNotRequired(false)
          setAccountsStatus(data.accounts_status || {})
        }

      }
    }
    fetchData()
  }, []);

  useEffect(() => {
    setKey(prev => prev + 1)
  }, [notRequired])

  /* 
  * This function is used to open the modal to edit the opening balances
  */
  const handleEdit = () => {
    setShowConfigurationsModal(true);
  };

  /*
  * This function is used to handle the redirection of the substantive test
  */
  const handleRedirection = (reference: string | undefined) => {
    if (!reference) return
    if (reference === "substantive_test_bank_conciliations") {
      const url = `/audix/engagement_substantive_test_bank_conciliations/index.load/${element.engagement_id}`;
      window.menuService?.breadcrumbAddLevel(url, T('Bank Conciliations'))
    }
  }

  /* 
  * This function is used to save the changes made in the opening balances
  */
  const handleConfigurationsModalAccept = async () => {
    setShowConfigurationsModal(false);
    const elementRepository = new ElementRepository()
    const lastOptions = JSON.parse(JSON.stringify(element.items_opening_balances))
    const lastTypeConclusion = element.type_conclusion
    element.items_opening_balances = itemOpeningBalances
    element.type_conclusion = typeConclusionSelected

    let success = await elementRepository.saveElement("view", element.args)
    if (!success) {
      element.items_opening_balances = lastOptions
      element.type_conclusion = lastTypeConclusion
      window.htmlHelpers?.swalError()
    }
  };

  /* 
  * This function is used to cancel the changes made in the opening balances
  */
  const handleConfigurationsModalCancel = () => {
    setTypeConclusionSelected(element.type_conclusion)
    setItemOpeningBalances(element.items_opening_balances)
    setShowConfigurationsModal(false)
  };

  /* 
  * This function is used to calculate the status of the opening balances
  */
  const calculateStatus = (accountCode: number | undefined) => {
    if (!accountCode) {
      return T("Pending");
    }

    if (accountsStatus[accountCode]) {
      return T("Performed");
    } else {
      return T("Pending");
    }
  }

  /*
* This function is used to handle the saving of the attachment
*/
  const handleSavingAttachment = (itemId: number, attachmentName: string) => {
    setItemOpeningBalances(
      (prev) =>
        prev.map((item) => (item.id === itemId ? { ...item, attachment_name: attachmentName } : item)
        )
    )
  }

  /**
   * This function is used to handle the change of the conclusion
   * @param answerSelected - the answer selected
   */
  const handleConclusionChange = (answerSelected: number | undefined) => {
    setTypeConclusionSelected(answerSelected)
  }

  return (
    <div key={key}>
      {notRequired === false ? (
        <React.Fragment>
          {noMateriality ? (
            <div className="card h-100 bg-light">
              <div className="card-body p-3">
                <h6>{T("It is necessary to enter the materiality file to see this component.")}</h6>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <ViewModeBase
                isEditable={true}
                handleEdit={handleEdit}
                permissions={element.permissions as Permissions}
              >
                <div>
                  <h6>{T("Evaluation of the Reasonableness of Opening Balances")}</h6>
                  <p>{T("To evaluate the reasonableness of opening balances, detailed procedures must be performed for the following items:")}</p>
                  <h6>{T("Suggested items by IARA for audit procedures on opening balances:")}</h6>
                  <ul>
                    <li>{T("Investments measured using the equity method")}</li>
                    <li>{T("Property, Plant, and Equipment")}</li>
                    <li>{T("Finance leases")}</li>
                    <li>{T("Intangibles")}</li>
                  </ul>
                  <p>{T("If the financial statements include any of the mentioned items, the corresponding accounts should be listed in the appropriate table.")}</p>
                  <table className="w-80 table-bordered">
                    <thead>
                      <tr>
                        <th className="p-2">{T("Item")}</th>
                        <th className="p-2">{T("Test Performed")}</th>
                        <th className="p-2">{T("Account Code")}</th>
                        <th className="p-2">{T("Status")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemOpeningBalances.map((item, index) => (
                        <tr key={index}>
                          <td>{item.title}</td>
                          <td>
                            <p>
                              {T("Attachment")}:
                              <br />
                              <em>
                                {item.attachment_name
                                  ? decryptUniqueFilename(item.attachment_name)
                                  : T("No attachment")}
                              </em>
                            </p>
                          </td>
                          <td>{item.account_code}</td>
                          <td>{calculateStatus(item.account_code)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {element.type_conclusion !== undefined && (
                    <div className="mt-2">
                      <b>{T("Conclusion")}: </b>
                      {element.type_conclusion === 1 ? (
                        <p>{T("Upon review of the client's opening balances, material errors affecting the results of the current period have been detected. Accordingly, such material errors will be recorded in the error spreadsheet and a corresponding accounting adjustment will be requested to ensure the fair presentation of the current period's financial statements.")}</p>
                      ) : element.type_conclusion === 2 ? (
                        <p>{T("After reviewing the client's opening balances, it has been determined that there are no material errors affecting the current period's performance. Therefore, it will not be necessary to implement additional procedures for this year's audit.")}</p>
                      ) : null}
                    </div>
                  )}
                </div>
              </ViewModeBase>
              <ModalWithButtons
                showModal={showConfigurationsModal}
                title={T("Opening Balances")}
                size="xl"
                onAccept={handleConfigurationsModalAccept}
                onCancel={handleConfigurationsModalCancel}>
                <div className="d-flex flex-column align-items-center">
                  <table className="w-80 table-bordered">
                    <thead>
                      <tr>
                        <th className="p-2">{T("Account Name")}</th>
                        <th className="p-2">{T("Test Performed")}</th>
                        <th className="p-2">{T("Account Code")}</th>
                        <th className="p-2">{T("Status")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemOpeningBalances.map((item, index) => (
                        <tr key={`saved-${index}`}>
                          <td className="p-2">{item.title}</td>
                          <td className="p-2">{item.reference_substantive_test ? (
                            <button className="btn btn-primary" onClick={() => handleRedirection(item.reference_substantive_test)}>{T("Redirect")}</button>
                          ) : (
                            <DropzoneComponent
                              elementId={element.id}
                              attachmentName={item.attachment_name}
                              onUpload={(newFileNames) => handleSavingAttachment(item.id, newFileNames)}
                            />
                          )}</td>
                          <td className="p-2">{item.account_code}</td>
                          <td className="p-2">{calculateStatus(item.account_code)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {itemOpeningBalances.length > 0 && (
                    <div className="card mt-2">
                      <h5 className="card-header">{T("Were material misstatements in the opening balances identified after performing the substantive procedures to assess their reasonableness?")}</h5>
                      <p className="card-body">{T("Select an option")}</p>
                      <select
                        id="dropdown"
                        className="form-select"
                        value={typeConclusionSelected === undefined ? "" : typeConclusionSelected}
                        onChange={(e) => handleConclusionChange(parseInt(e.target.value))}
                      >
                        <option value="" disabled>
                          {T("Select an option")}
                        </option>
                        {answerYesNo &&
                          answerYesNo.map((answer) => (
                            <option
                              key={`answer-${answer.id}`}
                              value={answer.id}
                            >
                              {answer.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>
              </ModalWithButtons>
            </ React.Fragment>
          )}
        </ React.Fragment>
      ) : (
        <div className="card h-100 bg-light">
          <div className="card-body p-3">
            <h6>{T("There is no requirement to test opening balances.")}</h6>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemOpeningBalancesViewMode;
