import { RequiredAccountConfigurationBankConciliations } from '../../models/special-document/ElementArgs'
import { getUrl } from '../../utils/app-config'
import { fetchAPI } from '../../services/api_services/fetchAPI'
import { FindingsEvaluationOptions } from '../../models/special-document/ElementArgs'
import { AccountData } from '../../components/substantive-tests/TablePrincipalBankConciliationsViewMode'


export interface BasicResponse {
  success: boolean
  error_message?: string
  finding_id?: number
  is_error_sheet?: boolean
}

export interface CreateResponse {
  basic_response: BasicResponse
  evaluation_types: FindingsEvaluationOptions[]
}

export interface RegistersOfTrialBalanceFile {
  id?: number
  consignment_number?: string
  type_account?: string
  code?: number
  name?: string
  amount?: number
  amount_in_other_accounts?: number
  amount_per_statement?: number
  difference_amount?: number
} 

export class TrialBalanceBankConciliationsRepository {
  private _jsonContentType = {
    'Content-Type': 'application/json'
  }
  constructor(private readonly fetcher: typeof fetchAPI = fetchAPI.bind(window)){}

  // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  async getRegistersOfTrialBalanceFile(engagement_id: number|null | undefined, documentId: number, reference: string | undefined): Promise<RegistersOfTrialBalanceFile> {
    const apiUrl = getUrl('substantive_test_api/get_registers_of_trial_balance_file/' + engagement_id + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: RegistersOfTrialBalanceFile = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }

  async updateTableBankConcilitaion(engagementId: number|null | undefined, documentId: number, reference: string | undefined, data_trial_balance: { [key: number]: Partial<AccountData> }): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/update_table_bank_conciliation/' + engagementId + '/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
          body: JSON.stringify(data_trial_balance)
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }  
  
  async updateDifferenceAmountTableBankConcilitaion(documentId: number, reference: string | undefined): Promise<BasicResponse> {
    const apiUrl = getUrl('substantive_test_api/update_difference_amount_bank_conciliation/' + documentId + '/' + reference)
    try {
      const response = await this.fetcher(
        apiUrl,
        {
          method: 'POST',
          headers: this._jsonContentType,
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      
      if (response.status !== 200) {
        throw new Error('Status is : ' + response.status)
      }

      const data: BasicResponse = await response.json()
      return data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error)
      throw error
    }
  }  
}
